import { prefetchMenuTree } from "modules/layout/hooks/useNavigationMenus";
import React from "react";
import {
  addApolloState,
  initializeApollo,
} from "../modules/apollo/apolloClient";
import DefaultPageLayout from "../modules/layout/components/DefaultPageLayout";
import {
  navigationQueries,
  teasersQuery,
} from "modules/ssgHelpers/sharedPageData";
import { preFetchCMSPage } from "modules/navigation/hooks/useCMSPage";

const CatchAll = ({ currentPage, menuTree }) => {
  return <DefaultPageLayout currentPage={currentPage} menuTree={menuTree} />;
};

export async function getStaticPaths() {
  return { paths: [], fallback: true };
}

export async function getStaticProps({ params, locale }) {
  const { slug } = params;
  const route = slug ? ["", ...slug].join("/") : "/home";

  const apolloClient = initializeApollo(null, { locale });
  await navigationQueries(apolloClient, locale);

  const currentPage = await preFetchCMSPage(apolloClient, { locale, route });

  if (currentPage?.teasers) {
    await teasersQuery(apolloClient, { locale, teasers: currentPage.teasers });
  }

  const menuTree = await prefetchMenuTree(apolloClient, { locale });
  if (!currentPage) return { notFound: true };

  if (locale === process.env.NEXT_PUBLIC_FAKE_LOCALE) {
    const [pageLocale] = Object.entries(currentPage._routes).find(
      ([, r]) => r === route,
    );
    const normalizedLocale = pageLocale === "default" ? "de" : pageLocale;
    return {
      redirect: {
        destination: `/${normalizedLocale}/${route}`,
        permanent: false,
      },
    };
  }

  return addApolloState(apolloClient, {
    props: { currentPage, menuTree },
    revalidate: 10,
  });
}

export default CatchAll;
