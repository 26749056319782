import React from "react";
import LocalizedLink from "modules/layout/components/LocalizedLink";

const ContentLink = ({ className, isActive, ...node }) => {
  return (
    <LocalizedLink
      page={{ pageId: node.route }}
      data-in-navigation-path={isActive}
      className={className}
    >
      {node.title}
    </LocalizedLink>
  );
};

const ContentPagesNavigation = ({
  currentPageNavigation: route,
  className = "",
}) => {
  if (!route?.children?.length) {
    return null;
  }

  const subNav = route.children.filter((child) => child.active);

  return (
    <nav className={`nav nav--side ${className}`}>
      <div className="sticky-top">
        <ContentLink
          {...route}
          className="nav--side__link is-parent mb-md-3"
          isActive={route.isActive}
        />
        {subNav.map((childNode, index) => {
          return (
            <ContentLink
              key={childNode.route}
              {...childNode}
              className={`nav--side__link ${index === 0 ? "pt-2" : ""}`}
              isActive={childNode.isActive}
            />
          );
        })}
      </div>
    </nav>
  );
};

export default ContentPagesNavigation;
